import stepMixin from '../../../mixins/stepMixin'
import { companies } from '../../../constants/general'
import FormInput from '../../form/formInput/FormInput.vue'
import FormSelect from '../../form/formSelect/FormSelect.vue'

export default {
    name: 'Driver',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin],

    components: {
        FormInput,
        FormSelect
    },

    data() {
        return {
            companyValues: companies
        }
    },

    methods: {
        validate() {
            let isValid = true

            if (this.isEmpty(this.field.lastName)) {
                isValid = false
            }

            if (this.isEmpty(this.field.firstName)) {
                isValid = false
            }

            if (this.isEmpty(this.field.company)) {
                isValid = false
            }

            if (this.isEmpty(this.field.employeeNumber)) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        }
    }
}
